import axios from "axios";

import { useAuthStore } from "./stores/auth";

function mapErrorCode(error) {
  const errorCode = error.response ? error.response.data.detail : error.code;
  switch (errorCode) {
    case "ACCOUNT_ALREADY_EXISTS":
      return "Account with this email address already exists.";
    case "COULD_NOT_VALIDATE_CREDENTIALS":
      return "Could not validate credentials. Please log in again to continue.";
    case "INCORRECT_EMAIL_OR_PASS":
      return "Incorrect email or password.";
    case "EXPIRED_ACCESS_TOKEN":
      return "Expired access token.";
    case "EXPIRED_REFRESH_TOKEN":
      return "Login has expired. Please log in again to continue.";
    case "PASSWORDS_DO_NOT_MATCH":
      return "Passwords do not match.";
    case "UNVERIFIED_ACCOUNT":
      return "Account has not been verified";
    case "PASSWORDS_DO_NOT_MATCH":
      return "Passwords do not match.";
    case "UNABLE_TO_SEND_VERIFICATION_MAIL":
      return "Unable to send verifaction email";
    case "INVALID_VERIFICATION_CODE":
      return "Invalid verification code or already verified account";
    case "VERIFICATION_TOKEN_EXPIRED":
      return "Verification token expired.";
    case "ACCESS_DENIED":
      return "Access denied.";
    case "COULD_NOT_CANCEL_SUBSCRIPTION":
      return "Could not cancel subscription. Please contact support.";
    case "TERMS_AND_CONDITION_NOT_ACCEPTED":
      return "Terms and conditions not accepted.";
    case "TOKEN_EXPIRED":
      return "Token is expired.";
    case "INVALID_TOKEN":
      return "Token is invalid.";
    case "NOT_FOUND":
      return "Not found.";

    default:
      return "An error occurred, please try again later.";
  }
}

const instance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_BASE_URL,
  //   headers: {
  //     common: {
  //       Authorization: "Bearer your_token_here",
  //       "Content-Type": "application/json",
  //     },
  //   },
});

instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status == 401 && originalRequest._retry !== true) {
      originalRequest._retry = true;

      const authStore = useAuthStore();

      if (error.response.data?.detail == "EXPIRED_ACCESS_TOKEN") {

        await authStore.memoizedRefresh();

        error.config.headers["Authorization"] = `Bearer ${authStore.authToken}`;
        return axios(error.config);
      } else {

        authStore.signOut();

        error.msg = mapErrorCode(error);
        return Promise.reject(error);
      }
    }
    error.msg = mapErrorCode(error);
    return Promise.reject(error);
  }
);

export default instance;
