<template>
  <get-started></get-started>
  <how-does-it-work></how-does-it-work>
  <pattern-catalog></pattern-catalog>
  <pricing></pricing>
  <news-letter-sign-up></news-letter-sign-up>
  <GoogleAuth v-if="authStore.authToken === null" :useOneTap="true" />
</template>

<script setup>
import GetStarted from "./../components/GetStarted.vue";
import HowDoesItWork from "./../components/HowDoesItWork.vue";
import PatternCatalog from "./../components/PatternCatalog.vue";
import Pricing from "./../components/Pricing.vue";
import NewsLetterSignUp from "./../components/NewsLetterSignUp.vue";
import GoogleAuth from "../components/auth/GoogleAuth.vue";
import { useAuthStore } from "../stores/auth";

const authStore = useAuthStore();
</script>

<style>
.app-md-and-up button.app-navbar-icon-button {
  padding: 0 1rem 0 0;
}

.app-sm-and-down button.app-navbar-icon-button {
  padding: 0 0.5rem 0 0;
}

.app-front-page-sheet {
  padding-bottom: 2rem;
}
</style>
