<template>
  <div class="welcome-section">
    <h3 class="app-page-title">{{ $t("welcome.Welcome") }}</h3>
    <subscription-notification></subscription-notification>

    <main-button @click="goToSubscriptionPlans">{{
      $t("welcome.View_subscription_plans")
    }}</main-button>
  </div>
  <v-row>
    <v-col cols="12">
      <pattern-catalog></pattern-catalog>
    </v-col>
  </v-row>
</template>

<script setup>
import { useRouter } from "vue-router";

import { composeRoute } from "../lib/navigationUtils";
import { useLangStore } from "./../stores/lang";
import avo from "./../userTracking/Avo";

import SubscriptionNotification from "../components/SubscriptionNotification.vue";
import MainButton from "./../components/general/MainButton.vue";
import PatternCatalog from "../components/PatternCatalog.vue";

const router = useRouter();
const langStore = useLangStore();
const locale = langStore.getLocale();

const goToSubscriptionPlans = () => {
  avo.viewSubscriptionPlans({ navigatedFrom: "welcomePageButton" });

  router.push(composeRoute(locale.value, "subscription"));
};
</script>

<style scoped>
.app-page-title {
  color: var(--knittable-lav-darker);
}

.welcome-section {
  padding-top: 3rem;
  padding-bottom: 5rem;
}
</style>
