export default {
  "common": {
    "Beanie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pipo"])},
    "Mittens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lapaset"])},
    "Socks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukat"])},
    "Sweater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulepaita"])},
    "Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitat"])},
    "Size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koko"])},
    "Choose_a_size_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse kokokategoria"])},
    "Size_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokokategoria"])},
    "Child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lapsi"])},
    "Grown_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aikuinen"])},
    "Custom_tailored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kustomoitu"])},
    "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kuukautta"])},
    "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vuotta"])},
    "Calculate_pattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laskelmoi ohje"])},
    "Knitting_gauge_from_swatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuletiheys mallitilkusta"])},
    "Unisex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unisex"])},
    "Man": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mies"])},
    "Woman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nainen"])},
    "Advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisävalinnat"])},
    "Ribbing_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joustinneuleen tyyppi"])},
    "Single_rib_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksinkertainen (1o, 1n)"])},
    "Double_rib_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tupla (2o, 2n)"])},
    "General": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yleinen"])},
    "Knitting_gauge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuletiheys"])},
    "stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["silmukkaa"])},
    "rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["riviä"])},
    "Design_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design yksityiskohdat"])},
    "The_Knittable_socks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knittable sukat"])},
    "The_Knittable_sweater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knittable neulepaita"])},
    "The_Knittable_mittens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knittable lapaset"])},
    "The_Knittable_beanie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knittable pipo"])},
    "default_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtui virhe, ole hyvä ja yritä myöhemmin uudelleen."])},
    "Licensing_disclaimer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tämä ohje on vain henkilökohtaiseen käyttöön, eikä sitä saa myydä tai jakaa missään muodossa. Ota yhteyttä osoitteeseen ", _interpolate(_named("email")), " lisensointivaihtoehdoista."])},
    "Share_your_version": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jaa oma versiosi sosiaalisessa mediassa hashtagilla #itsknittable ", _interpolate(_named("patternHashtag")), ". Neulomisen iloa!"])}
  },
  "footer": {
    "Slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinun ideasi ovat neulottavia"])},
    "Knitting_calculations_can_be_time_consuming_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulematikka voi olla monimutkaista ja aikaavievää. Islanniksi on sanonta \"Drjúg er lykkjustundin\", joka tarkoittaa, että jokainen neulomiseen käytetty hetki on hyvin käytetty. Knittablen avulla voit minimoida valmisteluun menevän ajan ja maksimoida neulomisajan."])},
    "Supported_by_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knittablea tukee:"])}
  },
  "knitting_gauge": {
    "Knitting_gauge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuletiheys"])},
    "Stitch_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silmukkamäärä"])},
    "Row_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kerrosmäärä"])},
    "Knitting_gauge_explained_1_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuletiheys on yhdistelmä silmukkatiheyttä ja kerrostiheyttä. Silmukkatiheys määrittää, kuinka monta silmukkaa mahtuu 10 cm leveään sileään neuleeseen, ja kerrostiheys määrittää, kuinka monta kerrosta tarvitaan 10 cm pitkän osan neulomiseen."])},
    "Knitting_gauge_explained_2_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Useimmissa langoissa on suositeltu neuletiheys tietylle puikkokoolle. Jos käsialasi on yleensä keskitasoa, voit kokeilla käyttää suositeltua tiheyttä ja puikkoja."])},
    "Knitting_gauge_explained_3_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos käsialasi on tiukkaa tai löyhää, voit käyttää ehdotettua tiheyttä vaihtamalla neulepuikkojen kokoa suurempaan tai pienempään. Huomaa, että parhaan tuloksen saavuttamiseksi on aina turvallisinta neuloa mallitilkku ennen projektin aloittamista neuletiheyden varmistamiseksi."])},
    "Stitch_gauge_explanation_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silmukoiden määrä 10 cm leveydellä"])},
    "Stitch_gauge_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esim. 18 silmukkaa Léttlopi-langalla neulottuna"])},
    "Row_gauge_explanation_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kerrosten määrä 10 cm pituudella"])},
    "Row_gauge_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esim. 24 kerrosta Léttlopi-langalla neulottuna"])},
    "Estimate_row_gauge_from_stitch_gauge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvioi kerrostiheys silmukkatiheyden pohjalta"])}
  },
  "socks": {
    "Shoe_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kengänkoko"])},
    "Choose_a_shoe_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse kengänkoko"])},
    "Ankle_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nilkan ympärysmitta"])},
    "Ankle_circumference_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ympärysmitta nilkan yläpuolelta (cm)"])},
    "Type_in_ankle_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita mitta"])},
    "Foot_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jalan ympärysmitta"])},
    "Foot_circumference_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ympärysmitta jalkaterän ympäri (cm)"])},
    "Type_in_foot_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita mitta"])},
    "Sock_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukan korkeus"])},
    "Sock_height_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukan korkeus kantapäästä pohkeeseen (cm)"])},
    "Type_in_sock_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita korkeus"])},
    "Sock_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukan pituus"])},
    "Sock_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukan pituus kantapäästä varpaisiin (cm)"])},
    "Type_in_sock_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita pituus"])},
    "Leg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varsi"])},
    "Leg_paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo ", _interpolate(_named("anklePieceLength")), " cm sileää neuletta."])},
    "Heel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kantapää"])},
    "Heel_next_up_heel_decreases_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyt kantapää muotoillaan tekemällä kantapääkavennuksia molemmilla puolilla:"])},
    "Heel_first_decrease_row_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. kerros kantapääkavennuksia:"])},
    "Heel_second_row_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. kerros:"])},
    "Foot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jalkaterä"])},
    "Knit_the_first_two_rounds_the_following_way_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulo seuraavat kaksi kierrosta seuraavasti:"])},
    "Knit_a_round_with_twisted_edge_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulo yksi kierros varmistaen, että poimit silmukat reunasta ja neulot ne silmukoiden takareunojen läpi."])},
    "Knit_in_the_round_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo suljettuna neuleena, kunnes sukan pituus on ", _interpolate(_named("footLengthMinusTipLength")), " cm kantapäästä mitattuna."])},
    "Toe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kärki"])},
    "Work_to_decreases_in_the_following_way_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulo kärkikavennukset seuraavasti:"])},
    "First_round_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. kierros (kavennuskierros):"])},
    "Decrease_round_description_first_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("firstNeedle")), " Neulo kunnes puikoilla on ", _interpolate(_named("tipMarginStitchCountPlusTwo")), " silmukkaa jäljellä. Neulo kaksi silmukkaa yhteen, ", _interpolate(_named("conditionalAdditionalLastStitch")), "."])},
    "_and_then_knit_x_stitches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["neulo ", _interpolate(_named("tipMarginStitchCount"))])},
    "Decrease_round_description_second_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("secondNeedle")), " ", _interpolate(_named("conditionalAdditionalFirstStitch")), "Nosta silmukka neulomatta, neulo yksi silmukka, vedä nostettu silmukka neulotun silmukan yli. Neulo loput silmukat."])},
    "Knit_one_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulo 1o. "])},
    "Decrease_round_description_third_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("thirdNeedle")), " Sama kuin ensimmäisellä puikolla."])},
    "Decrease_round_description_fourth_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fourthNeedle")), " Sama kuin toisella puikolla."])},
    "Knit_decrease_rounds_from_here_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jatka kavennuskierrosten neulomista, kunnes jäljellä on ", _interpolate(_named("finalRowStitchCount")), " silmukkaa. Katkaise lanka ja vedä se loppujen silmukoiden läpi. Päättele langanpätkät."])},
    "Knit_the_other_sock_the_same_way_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulo toinen sukka samalla tavalla."])},
    "v1_1_0": {
      "Leg_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Luo ", _interpolate(_named("castOnStitchCount")), " silmukkaa neljälle sukkapuikolle (", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " ja ", _interpolate(_named("stitchCountEachEvenNeedle")), " silmukkaa) tai pyöröpuikoille. Neulo joustinneuletta suljettuna neuleena (neulo  ", _interpolate(_named("cuffPatternRepCountHalf")), "o ", _interpolate(_named("cuffPatternRepCountHalf")), "n) kunnes työ on ", _interpolate(_named("cuffLength")), " cm pitkä."])},
      "Heel_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aloita nyt kantalapun neulominen. Neulo ", _interpolate(_named("heelflapStitchCountLeft")), " silmukkaa 1. puikolle, käännä työ ympäri, nosta ensimmäinen silmukka nurin neulomatta. Neulo kaikki ", _interpolate(_named("heelflapStitchCountLeftMinusOne")), " 1. puikon silmukkaa nurin ja aseta silmukkamerkki. Sitten neulo nurin ", _interpolate(_named("heelflapStitchCountRight")), " silmukkaa 4. puikolta 1. puikolle. Ensimmäisellä puikolla on nyt ", _interpolate(_named("heelflapStitchCount")), " silmukkaa, joka on kantalapun alku. Loput ", _interpolate(_named("instepStitchCount")), " silmukkaa muodostavat jalan päällisen."])},
      "Heel_paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jatka ", _interpolate(_named("heelflapStitchCount")), " silmukoiden neulomista tasossa sileänä neuleena, (neulo työn oikealla puolella oikein, nurjalla puolella nurin, nostaen aina ensimmäisen silmukan neulomatta) kunnes olet neulonut ", _interpolate(_named("heelFlapRowCount")), " kerrosta kantapäälappuun, silmukkamerkistä oikealle laskettuna. Nyt reunasilmukoita pitäisi olla ", _interpolate(_named("heelFlapEdgeStitchCount")), " kantalapun molemmilla puolilla ja seuraava kerros  alkaa työn oikealla puolella."])},
      "Heel_first_decrease_row_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nosta ensimmäinen silmukka oikein neulomatta, neulo oikein kunnes saavutat silmukkamerkin. Neulo oikein ", _interpolate(_named("heelFlapCenterLeftMinusOne")), " silmukkaa. Nosta 1o neulomatta, neulo 1o, vedä nostettu silmukka neulotun silmukan yli ja käännä työ."])},
      "Heel_second_row_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nosta ensimmäinen silmukka nurin neulomatta, neulo nurin kunnes saavutat silmukkamerkin. Neulo nurin ", _interpolate(_named("heelFlapCenterRightMinusOne")), " silmukkaa. Neulo 2n yhteen ja käännä työ."])},
      "Heel_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toista näitä kahta kerrosta, kunnes kaikki kantalapun sivulla olevat silmukat on kavennettu pois."])},
      "Heel_paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nosta ensimmäinen silmukka neulomatta, neulo oikein ", _interpolate(_named("heelFlapCenterRightMinusOne")), " silmukkaa kunnes saavutat silmukkamerkin."])},
      "Foot_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Loput sukasta neulotaan suljettuna neuleena. Jaa kantapään silmukat takaisin kahdelle puikolle ja poimi kaikki ", _interpolate(_named("heelFlapEdgeStitchCount")), " reunasilmukkaa kantalapun molemmilta puolilta käyttäen näitä kahta puikkoa. Lisää yksi silmukka kummallekin puikolle poimimalla silmukka kantalapun alkupäästä (silmukka on kantalapun ensimmäisellä kierroksella). Kierroksen alku on nyt näiden kahden puikon välissä, kantapään keskellä."])},
      "Foot_paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nyt puikoilla pitäisi olla yhteensä ", _interpolate(_named("totalStitchCountBeforeHeelDecrease")), " silmukkaa (", _interpolate(_named("firstNeedleStitchCount")), ", ", _interpolate(_named("secondNeedleStitchCount")), ", ", _interpolate(_named("thirdNeedleStitchCount")), " ja ", _interpolate(_named("fourthNeedleStitchCount")), " silmukkaa). Jos käytät pyöröpuikkoa, aseta silmukkamerkit “puikkojen” vaihdoskohtiin."])},
      "Knit_a_round_with_decreases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulo kaikki 1. puikon silmukat, kunnes puikolla on 2 silmukkaa jäljellä, neulo 2o yhteen. Neulo 2. ja 3. puikon silmukat. Nosta 4. puikon ensimmäinen silmukka oikein neulomatta, neulo 1 silmukka ja vedä nostettu silmukka neulotun yli. Neulo loput puikon silmukoista."])},
      "Repeat_the_two_rounds_above_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Toista toinen ja kolmas kierros ", _interpolate(_named("heelDecreaseCountMinusOne")), " kertaa kunnes puikoilla on yhteensä ", _interpolate(_named("footStitchCount")), " silmukkaa."])},
      "Make_sure_equal_number_of_stitches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Varmista, että silmukat on jaettu tasan puikkojen kesken, tai ", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " ja ", _interpolate(_named("stitchCountEachEvenNeedle")), " silmukkaa, ennen kuin aloitat kärkikavennukset. Jos käytät pyöröpuikkoja, varmista että silmukkamerkit ovat 1. ja 2. puikon vaihtumiskohdassa, sekä 3. ja 4. puikon vaihtumiskohdassa."])}
    },
    "pattern_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukat neulotaan joustinvarresta alaspäin 10% negatiivisella väljyydellä. Jalkaosa on sileää neuletta ja kantapää muotoillaan kantalapulla ja kantapääkavennuksilla sopivan istuvuuden saavuttamiseksi. Jalkaterä muotoillaan astettaisilla kavennuksilla siistin lopputuloksen saamiseksi."])}
  },
  "common2": {
    "First_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. kierros:"])},
    "Second_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. kierros:"])},
    "Second_and_third_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. ja 3. kierros:"])},
    "Second_to_fourth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2:sta 4:nteen kierrokseen:"])},
    "Third_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. kierros:"])},
    "Fourth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. kierros:"])},
    "Fifth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5.kierros:"])},
    "Sixth_and_seventh_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. ja 7. kierros:"])},
    "Eighth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8. kierros:"])},
    "Ninth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9. kierros:"])},
    "Xth_round_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("roundIndex")), ". kierros:"])},
    "First_needle_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. puikko:"])},
    "Second_needle_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. puikko:"])},
    "Third_needle_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. puikko:"])},
    "Fourth_needle_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. puikko:"])},
    "Knit_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulo."])},
    "Knit_a_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulo kavennuskierros."])},
    "First_row_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. rivi:"])},
    "Second_row_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. rivi:"])},
    "Third_row_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. rivi:"])},
    "Xth_row_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("rowIndex")), ". rivi:"])}
  },
  "mittens": {
    "Cuff_circumference_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rannekkeen ympärysmitta"])},
    "Cuff_circumference_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ympärysmitta ranteen ympäriltä mitattuna (cm)"])},
    "Type_in_cuff_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita mitta"])},
    "Cuff_length_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rannekkeen pituus"])},
    "Cuff_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rannekkeen pituus (cm)"])},
    "Type_in_cuff_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita mitta"])},
    "Hand_circumference_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kämmenen ympärysmitta"])},
    "Hand_circumference_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ympärysmitta kämmenen ympäri mitattuna (cm)"])},
    "Type_in_hand_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita mitta"])},
    "Hand_length_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käden pituus"])},
    "Hand_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käden pituus ranteesta sormenpäihin (cm)"])},
    "Type_in_hand_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita mitta"])},
    "Felting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huovutus"])},
    "Felting_information_bubble_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun lapaset on tarkoitus huovuttaa, neulotaan ne suuremmassa koossa kuin lopullinen toivottu koko. Kun lapaset ovat valmiit, ne voi huovuttaa joko käsin tai pyykkikoneessa, kunnes ne ovat kutistuneet haluttuun kokoon."])},
    "Felting_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huovuttamisen jälkeen neulepinnasta tulee tiheämpää, mikä tekee lapasista kestävämmät, vedenpitävämmät ja  lämpimämmät."])},
    "Felting_density_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huovutustiheys"])},
    "Choose_how_much_to_felt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse huovutuksen taso"])},
    "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei huovutusta"])},
    "Felt_a_little": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vähän (neulottu 10% suurempana)"])},
    "Felt_somewhat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enemmän (neulottu 20% suurempana)"])},
    "Felt_a_lot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paljon (neulottu 30% suurempana)"])},
    "Yarn_for_felting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langan valinta huovuttamiseen"])},
    "Yarn_for_felting_info_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki ei-superwash-langat huovuttuvat, mutta lopputulos vaihtelee lankatyyppien välillä. Neuletiheys vaikuttaa myös lopputulokseen, sillä löyhempi neulos huovuttuu enemmän kuin tiheä."])},
    "Yarn_for_felting_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaihtoehdot \"Vähän\", \"Enemmän\" ja \"Paljon\" antavat suuntaa lopullisen neuleen koosta, sillä tarkemmat mitat riippuvat langan tyypistä ja neulojan neuletiheydestä."])},
    "Suggestion_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lankasuositus:"])},
    "Yarn_for_felting_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plötulopi tuplana neulottuna, neuletiheys 16 s/10 cm."])},
    "Cuff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranneke"])},
    "Cuff_felting_side_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joustinneule lyhenee huovuttuessaan enemmän kuin sileä neule, joten rannekkeen pituutta on lisätty suuremmassa määrin kuin muita lapasen mittasuhteita."])},
    "Hand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käsi"])},
    "Knit_a_round_and_increase": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo kierros sileää neuletta ja lisää samalla tasaisesti ", _interpolate(_named("handCuffStitchCountDiff")), " silmukkaa kierroksen aikana. Nyt puikoilla pitäisi olla ", _interpolate(_named("handStitchCount")), " silmukkaa."])},
    "Knit_in_the_round_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo suljettua neuletta, kunnes lapasen pituus on ", _interpolate(_named("beforeThumbLength")), " cm rannekkeesta mitattuna."])},
    "Right_mitten_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oikea lapanen:"])},
    "Left_mitten_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vasen lapanen:"])},
    "Decrease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kavenna"])},
    "Work_to_decreases_in_the_following_way_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulo kärkikavennukset seuraavanlaisesti::"])},
    "First_round_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. kierros (kavennuskierros):"])},
    "Knit_one_stitch_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulo 1 silmukka."])},
    "Decrease_round_description_first_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("firstNeedle")), " ", _interpolate(_named("conditionalAdditionalFirstStitch")), " Nosta 1 silmukka oikein neulomatta, neulo 1 silmukka ja vedä nostettu silmukka neulotun yli. Neulo loput puikon silmukoista."])},
    "Decrease_round_description_second_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("secondNeedle")), " Neulo kunnes puikolla on ", _interpolate(_named("tipMarginStitchCountPlusTwo")), " silmukkaa.  Neulo 2 oikein yhteen ", _interpolate(_named("conditionalAdditionalLastStitch")), "."])},
    "_and_then_knit_x_stitches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([", neulo ", _interpolate(_named("tipMarginStitchCount"))])},
    "Decrease_round_description_third_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("thirdNeedle")), " Neulo samoin kuin 1. puikko."])},
    "Decrease_round_description_fourth_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fourthNeedle")), " Neulo samoin kuin 2. puikko."])},
    "Knit_decrease_rounds_from_here_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo nyt kavennuskierroksia joka kierroksella, kunnes jäljellä on ", _interpolate(_named("finalRowStitchCount")), " silmukkaa. Katkaise lanka ja vedä se kaikkien silmukoiden läpi."])},
    "Thumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peukalo"])},
    "Thumb_description_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pura merkkilanka avataksesi paikan peukalolle. Poimi ", _interpolate(_named("thumbOpeningStitchCountMinusOne")), " silmukkaa peukaloaukon yläosasta ja ", _interpolate(_named("thumbOpeningStitchCount")), " alaosasta. Näiden lisäksi poimi 2 silmukkaa peukaloaukon molemmilta puolilta. Nyt puikoilla pitäisi olla ", _interpolate(_named("initialThumbStitchCount")), " silmukkaa: ", _interpolate(_named("initialThumbStitchCountHalfFloor")), " peukalon yläosalle ja ", _interpolate(_named("initialThumbStitchCountHalfCeil")), " alaosalle. Jaa silmukat tasaisesti sukkapuikoille, tai jaa ylä- ja alaosan silmukoiksi jos käytät pyöröpuikkoa ja magic loop -tekniikkaa."])},
    "Thumb_second_round_description_side_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huomaa, että kierros alkaa nyt peukalon yläosasta oikeaa lapasta neuloessa, ja kierros alkaa peukalon alaosasta vasenta lapasta neuloessa."])},
    "Thumb_second_round_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kavenna peukalosta ", _interpolate(_named("stitchesKnitTogetherInSecondRow")), " silmukkaa neulomalla peukalon alaosa seuraavanlaisesti: Neulo kaksi oikein yhteen, neulo kunnes alaosassa on jäljellä on 2 silmukkaa, nosta 1 silmukka oikein neulomatta, neulo 1 silmukka ja vedä nostettu silmukka neulotun yli. Neulo peukalon yläosa ilman kavennuksia. Nyt puikoilla pitäisi olla ", _interpolate(_named("thumbStitchCount")), " silmukkaa."])},
    "Thumb_break_the_yarn_and_pull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katkaise lanka ja vedä se loppujen silmukoiden läpi."])},
    "Weave_in_all_ends_and_knit_the_other_mitten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päättele langanpätkät. Neulo toinen lapanen samalla tavalla, mutta seuraa ohjeita jotka on merkattu \"Vasen lapanen\" , jos neuloit ensin oikean lapasen."])},
    "Felting_description_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lapaset voi huovuttaa käsin tai pyykkikoneessa. Käsin huovuttaminen mahdollistaa paremman muodon ja koon hallinnan, kun taas pyykkikoneella huovuttamisen lopputulos riippuu paljon koneesta ja siinä olevista pesuohjelmista. Huovutettua lapasta voi usein kuitenkin manipuloida vielä jälkikäteen venyttämällä märkää lapasta jos koko on liian pieni, tai huovuttamalla uudestaan korkeammassa lämpötilassa jos koko on liian suuri."])},
    "Felting_description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yleisesti ottaen korkeampi lämpötila, villapesuaineen/huuhteluaineen käyttäminen, tai muiden vaatteiden seassa peseminen ovat kaikki lopputulokseen vaikuttavia tekijöitä. Jos peset lapaset muiden vaatteiden joukossa, pidä huolta että vaatteet eivät ole nyppääntyvää sorttia."])},
    "Felting_a_little_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laita lapaset pesukoneeseen lyhyellä ohjelmalla (ei villapesuohjelma) linkousohjelmalla ja 40° lämpötilalla. Käytä pientä määrää villasaippuaa/huuhteluainetta."])},
    "Felting_somewhat_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laita lapaset pesukoneeseen täydellä tavallisella ohjelmalla (ei villapesuohjelma) linkousohjelmalla ja 40° lämpötilalla. Käytä pientä määrää villasaippuaa/huuhteluainetta."])},
    "Felting_a_lot_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laita lapaset pesukoneeseen täydellä tavallisella ohjelmalla (ei villapesuohjelma) linkousohjelmalla ja 60° lämpötilalla. Käytä pientä määrää villasaippuaa/huuhteluainetta."])},
    "v1_1_0": {
      "Cuff_cast_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Luo ", _interpolate(_named("castOnStitchCount")), " silmukkaa neljälle sukkapuikolle (", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " ja ", _interpolate(_named("stitchCountEachEvenNeedle")), " silmukkaa) tai pyöröpuikoille. Liitä suljetuksi neuleeksi ja neulo joustinneuletta (neulo  ", _interpolate(_named("cuffPatternRepCountHalf")), "o, neulo ", _interpolate(_named("cuffPatternRepCountHalf")), "n) kunnes työ on ", _interpolate(_named("calculatedCuffLength")), " cm pitkä."])},
      "Make_sure_half_the_stitches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Huolehdi, että silmukat on jaettu tasaisesti puikoille, tai ", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " ja ", _interpolate(_named("stitchCountEachEvenNeedle")), " silmukkaa, ennen kuin jatkat neulomista. Mikäli käytät pyöröpuikkoa, aseta silmukkamerkki 2. ja 3. puikon vaihtumiskohtaan, (", _interpolate(_named("halfHandStitchCount")), " jälkeen)."])},
      "Right_mitten_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo ", _interpolate(_named("halfHandStitchCount")), " silmukkaa 1. ja 2. Puikolta (tai silmukkamerkkiin asti), ja ", _interpolate(_named("thumbMarginStitchCount")), " silmukkaa 3. puikolta. Neulo ", _interpolate(_named("thumbOpeningStitchCount")), " silmukkaa käyttäen erillistä langanpätkää. Siirrä langanpätkällä neulotut silmukat takaisin vasemmalle puikolle ja neulo ne lapasen pohjalangalla. Neulo loput kierroksesta tavallisesti."])},
      "Left_mitten_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo ", _interpolate(_named("stitchCountUntilThumb")), " silmukkaa. Neulo ", _interpolate(_named("thumbOpeningStitchCount")), " silmukkaa käyttäen erillistä langanpätkää. Siirrä langanpätkällä neulotut silmukat takaisin vasemmalle puikolle ja neulo ne lapasen pohjalangalla, jatka samoin myös loput ", _interpolate(_named("thumbMarginStitchCount")), " 2. puikon silmukoista (tai kunnes saavutat silmukkamerkin). Neulo loput kierroksesta tavallisesti."])},
      "Make_sure_again_half_the_stitches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Huolehdi jälleen, että kaikki silmukat on jaettu tasaisesti puikoille, tai ", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " ja ", _interpolate(_named("stitchCountEachEvenNeedle")), " silmukkaa, ennen kuin jatkat neulomista. Mikäli käytät pyöröpuikkoa, huolehdi että silmukkamerkki on yhä 2. Ja 3. Puikon vaihtumiskohdassa (", _interpolate(_named("halfHandStitchCount")), " silmukan jälkeen)."])},
      "Thumb_first_round_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aloita kierros peukalon aukon kämmenpuoleiselta puolelta ja neulo ensimmäisen puoliskon kaikki ", _interpolate(_named("thumbInitialHalfStitchCount")), " silmukkaa varmistaen, että neulot sivuilta poimitut silmukat oikein takareunasta kiertäen. Neulo toinen puolisko samalla tavalla."])},
      "Thumb_second_round_right_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo kaikki ", _interpolate(_named("thumbInitialUpperHalfStitchCount")), " ensimmäisen puoliskon silmukkaa. Neulo 2 oikein yhteen. Neulo ", _interpolate(_named("thumbInitialLowerHalfStitchCountMinusFour")), " silmukkaa jättäen toisesta puoliskosta 2 silmukkaa, nosta 1 oikein neulomatta, neulo 1 oikein ja vedä nostettu silmukka neulotun yli."])},
      "Thumb_second_round_left_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo 2 oikein yhteen. Neulo ", _interpolate(_named("thumbInitialLowerHalfStitchCountMinusFour")), " silmukkaa, jättäen ensimmäisestä puoliskosta 2 silmukkaa, nosta 1 oikein neulomatta, neulo 1 oikein ja vedä nostettu silmukka neulotun yli. Neulo kaikki ", _interpolate(_named("thumbInitialUpperHalfStitchCount")), " toisen puoliskon silmukkaa tavallisesti."])},
      "Thumb_knit_in_the_round_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Puikoilla pitäisi nyt olla There ", _interpolate(_named("thumbStitchCount")), " silmukkaa. Neulo suljettuna neuleena kunnes peukalon pituus on ", _interpolate(_named("thumbLengthMinusThumbTiplength")), " cm."])},
      "Thumb_knit_decrease_rounds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo nyt kavennuskierrokset seuraavanlaisesti joka toisella kierroksella kunnes puikoilla on jäljellä ", _interpolate(_named("thumbFinalRowStitchCount")), " silmukkaa."])},
      "Thumb_decrease_round_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nosta 1 oikein neulomatta, neulo 1 oikein ja vedä nostettu silmukka neulotun yli, neulo kunnes ensimmäisellä puoliskolla on 2 silmukkaa jäljellä, neulo 2 oikein yhteen. Nosta 1 oikein neulomatta, neulo 1 oikein ja vedä nostettu silmukka neulotun yli, neulo kunnes toisella puoliskolla on 2 silmukkaa jäljellä, neulo 2 oikein yhteen"])}
    },
    "pattern_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lapaset neulotaan joustinneulevarresta ylöspäin. Kämmenosa on sileää neuletta, peukalo lisätään jälkikäteen käyttämällä erillistä jämälankaa. Lapasen yläosa muotoillaan astettaisilla kavennuksilla siistin lopputuloksen saamiseksi."])}
  },
  "common3": {
    "Size_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koko"])},
    "Choose_a_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse koko"])},
    "Print_pattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tulosta ohje"])},
    "This_browser_cannot_print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitettavasti tätä selainta ei voi käyttää ohjeen tulostamiseen. Yritä uudestaa toisella selaimella, esim. Chrome."])},
    "Decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kavennuskierros:"])}
  },
  "beanie": {
    "Head_circumference_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päänympärys."])},
    "Head_circumference_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päänympärys mitattuna otsan ja takaraivon leveimmän kohdan ympäri (cm)"])},
    "Type_in_head_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita mitta"])},
    "Brim_height_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reunuksen korkeus"])},
    "Brim_height_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pipon reunuksen korkeus taitettuna pipon alaosasta (cm)"])},
    "Type_in_brim_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita reunuksen korkeus"])},
    "There_are_too_few_cast_on_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tässä pipossa on liian vähän silmukoita. Kasvata neuletiheyttä tai valitse suurempi koko."])},
    "Body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vartalo"])},
    "Ribbing_coverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joustinneuleen kattavuus"])},
    "Whole_beanie_ribbed_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koko pipo"])},
    "Only_brim_ribbed_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vain reunus"])},
    "Decrease_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kavennustyyppi"])},
    "Even_decrease_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näkymättömät kavennukset"])},
    "Cross_decrease_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ristikavennukset"])},
    "Body_cast_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Luo ", _interpolate(_named("castOnStitchCount")), " silmukkaa pyöröpuikoille käyttäen puikkokokoa jolla saat halutun neuletiheyden."])},
    "Body_cast_on_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaksinkertainen luomistapa (tubular cast on) tekee pipon reunukselle siistin reunan, mutta perinteinen luomistapa toimii hyvin myös."])},
    "body_description_ribbed_start": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Liitä suljetuksi neuleeksi ja neulo reunuksen joustinneuletta (neulo ", _interpolate(_named("cuffPatternRepCountHalf")), "o, neulo ", _interpolate(_named("cuffPatternRepCountHalf")), "n) kunnes työ on ", _interpolate(_named("straightCuffLength")), " cm pitkä."])},
    "body_description_stockinette_top_zero_fold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo ", _interpolate(_named("lengthBeforeDecreaseMinusStraightCuffLength")), " cm sileää neuletta."])},
    "body_description_stockinette_top_nonzero_fold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo ", _interpolate(_named("lengthBeforeDecreaseMinusStraightCuffLength")), " cm sileää neuletta, kunnes työ on ", _interpolate(_named("lengthBeforeDecrease")), " cm, tai ", _interpolate(_named("lengthBeforeDecreaseMinusFoldLength")), " cm jos pituus mitataan taitetulla ", _interpolate(_named("foldLength")), " cm reunuksella. Taitoksen kanssa otettu mitta on tarkempi, etenkin jos neulomiseen on käytetty paksumpaa lankaa, sillä osa pipon korkeudesta menee itse taitokseen."])},
    "body_description_whole_ribbed_nonzero_fold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Liitä suljetuksi neuleeksi ja neulo joustinneuletta (neulo ", _interpolate(_named("cuffPatternRepCountHalf")), "o, neulo ", _interpolate(_named("cuffPatternRepCountHalf")), "n) kunnes työ on ", _interpolate(_named("straightCuffLength")), " cm pitkä, tai ", _interpolate(_named("straightCuffLengthMinusFoldLength")), " cm jos pituus mitataan taitetulla ", _interpolate(_named("foldLength")), " cm reunuksella. Taitoksen kanssa otettu mitta on tarkempi, etenkin jos neulomiseen on käytetty paksumpaa lankaa, sillä osa pipon korkeudesta menee itse taitokseen."])},
    "Decrease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kavennukset"])},
    "First_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. kavennuskierros:"])},
    "First_even_decrease_round_description_2x2_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*neulo 2o, neulo 2n yhteen.**"])},
    "First_even_decrease_round_description_1x1_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Nosta 1o neulomatta, neulo 1o ja vedä nostettu silmukka neulotun yli. Neulo 1o neulo 1n.**"])},
    "First_even_decrease_round_description_stockinette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Neulo 2o, neulo 2o yhteen.**"])},
    "Repeat_from_star_to_2star_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo koko kierros toistaen *-**. Nyt puikoilla pitäisi olla ", _interpolate(_named("castOnStitchCountMinusEvenlyDecreasedStitchesCount")), " silmukkaa."])},
    "Knit_x_rounds_without_decreases": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo ", _interpolate(_named("numberOfRowsBetweenDecreases")), " kierrosta ilman kavennuksia."])},
    "Second_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. kavennuskierros:"])},
    "Second_even_decrease_round_description_2x2_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Neulo 2o yhteen, neulo 1n.**"])},
    "Second_even_decrease_round_description_1x1_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Neulo 2o yhteen, neulo 1n.**"])},
    "Second_even_decrease_round_description_stockinette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Neulo 2o yhteen, neulo 1o.**"])},
    "Third_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. kavennuskierros:"])},
    "Third_even_decrease_round_description_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Nosta 1o neulomatta, neulo 1o ja vedä nostettu silmukka neulotun yli.**"])},
    "Third_even_decrease_round_description_stockinette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Neulo 2o yhteen.**"])},
    "Final_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viimeinen kavennuskierros:"])},
    "Final_even_decrease_round_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Neulo 2o yhteen.**"])},
    "Repeat_from_star_to_2star_with_side_note": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo koko kierros toistaen *-**. Puikoilla pitäisi nyt olla ", _interpolate(_named("castOnStitchCountMinusEvenlyDecreasedStitchesCount")), " silmukkaa ", _interpolate(_named("conditionalLastStitchSideNote")), "."])},
    "the_last_stitch_is_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" (neulo viimeinen silmukka)"])},
    "Break_the_yarn_and_pull_weave_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katkaise lanka ja vedä se loppujen silmukoiden läpi. Päättele langanpätkät."])},
    "Cross_decrease_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kavennuskierroksen aikana kavennetaan 2 silmukkaa 4 kohtaa (yhteensä 8 kavennusta). Aseta silmukkamerkki jokaisen kavennusparin keskelle merkkaamaan kavennuksen paikkaa."])},
    "First_round_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. kierros (kavennuskierros):"])},
    "Back_of_the_head_left_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pään takaosa, vasen:"])},
    "Work_x_stitches_in_x_stitch_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo ", _interpolate(_named("numberOfStitchesBetweenDecreases")), " silmukkaa ", _interpolate(_named("typeOfStitch")), "."])},
    "stockinette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sileää neuletta"])},
    "rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["joustinneuletta"])},
    "Decrease_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kavenna:"])},
    "First_cross_decrease_round_description_stockinette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nosta 1 silmukka oikein neulomatta, neulo 1o ja vedä nostettu silmukka neulotun yli. Neulo 2o yhteen."])},
    "First_cross_decrease_round_description_rib": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo 2o yhteen ", _interpolate(_named("IntermediateRibStitches")), ". Nosta 1o neulomatta, neulo 1o ja vedä nostettu silmukka neulotun yli."])},
    "purl_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", neulo 1n"])},
    "work_x_stitches_in_rib_stitch": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([", neulo ", _interpolate(_named("crossDecreaseColumnStitchesMinusTwo")), " silmukkaa joustinneuletta"])},
    "Left_side_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vasen puoli:"])},
    "Work_x_stitches_in_x_stitch_and_then_decrease": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo ", _interpolate(_named("numberOfStitchesBetweenDecreases")), " silmukkaa ", _interpolate(_named("typeOfStitch")), " ja tee sitten kavennus."])},
    "Forehead_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otsa:"])},
    "Right_side_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oikea puoli:"])},
    "Back_of_the_head_right_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pään takaosa, oikea:"])},
    "Now_the_number_of_stitches_has_decreased_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Silmukoita on nyt kavennettu 8, jäljellä on ", _interpolate(_named("castOnStitchCountMinusEight")), " silmukkaa."])},
    "Knit_the_round_in_x_without_decreases": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo kierros ", _interpolate(_named("typeOfStitch")), " ilman kavennuksia."])},
    "Knit_a_decrease_round_except_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulo kavennuskierros, jonka aikana kavennukset tehdään samoissa kohdissa kuin 1. kierroksella (paitsi nyt kavennusten välillä on 2 silmukkaa vähemmän)."])},
    "Repeat_the_last_two_rounds_x_times_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Toista viimeiset kaksi kierrosta vielä ", _interpolate(_named("everyOtherRowDecreaseCountMinusOne")), " kertaa, kunnes puikoilla on jäljellä ", _interpolate(_named("stitchCountBeforeEveryRowDecreases")), " silmukkaa."])},
    "Now_there_should_be_x_stitches_remaining": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nyt puikoilla pitäisi olla ", _interpolate(_named("stitchCountBeforeEveryRowDecreases")), " silmukkaa."])},
    "Work_a_decrease_in_every_round_until_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nyt neulo kavennuskierros jokaisella kierroksella, kunnes puikoilla on jäljellä ", _interpolate(_named("crossDecreaseFinalRowStitchCount")), " silmukkaa. Katkaise lanka ja vedä se loppujen silmukoiden läpi. Päättele langanpätkät."])},
    "pattern_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pipo neulotaan alhaalta ylöspäin taitetulla reunalla, neulepinnan vaihtoehtoina joustinneule tai sileä neule. Pipossa on 10% negatiivista väljyyttä, sen istuvuus on napakka ja astettaiset kavennukset päälaella takaavat siistin lopputuloksen."])}
  },
  "common4": {
    "Design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulepinta"])}
  },
  "navbar": {
    "Patterns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuleohjeet"])},
    "Tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työkalut"])},
    "Sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu sisään"])},
    "Sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu ulos"])},
    "Subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilaa"])},
    "Get_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aloita nyt"])}
  },
  "common_user_settings": {
    "First_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensimmäinen nimi"])},
    "Last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukunimi"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salasana"])},
    "New_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uusi salasana"])},
    "Confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahvista salasana"])},
    "E_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköposti"])}
  },
  "sign_in": {
    "Not_a_registered_user_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eikö sinulla ole käyttäjätunnusta?"])},
    "Sign_up_instead_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekisteröidy nyt."])},
    "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä"])},
    "Welcome_back_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tervetuloa takaisin!"])},
    "Forgot_password_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unohditko salasanan?"])},
    "Get_a_new_one_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanki uusi salasana."])},
    "Sign_in_with_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tai kirjaudu sisään sähköpostilla"])}
  },
  "sign_up": {
    "Already_a_registered_user_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onko sinulla jo käyttäjätunnus?"])},
    "Sign_in_instead_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu sisään nyt."])},
    "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä"])},
    "Suceess_Your_account_has_been_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hienoa! Käyttäjätunnus on luotu onnistuneesti."])},
    "Your_free_trial_starts_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilmainen 14 päivän kokeilujakso on alkanut."])},
    "We_ve_sent_a_verification_email_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olemme lähettäneet vahvistusviestin sähköpostiisi. Tarkista saapuneiden viestien kansiosi ja klikkaa viestissä olevaa linkkiä käyttäjätunnuksen aktivoimiseksi."])},
    "Need_help_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarvitsetko apua?"])},
    "Contact_our_support_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ota yhteyttä tukeen."])},
    "Create_a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo käyttäjätunnus"])},
    "Knittable_can_send_me_marketing_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahdon vastaanottaa neuleinspiraatiota ja uutisia uusimmista neuleohjeista ja työkaluista sähköpostiini."])},
    "I_accept_terms_and_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksyn Knittablen käyttöehdot"])},
    "Sign_up_with_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tai rekisteröidy sähköpostilla"])}
  },
  "social_auth": {
    "Google_sign_up_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos olet aiemmin rekisteröitynyt sähköpostilla, liitämme Google-tilisi olemassa olevaan tiliisi."])}
  },
  "verify_email": {
    "Verify_my_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahvista sähköpostiosoitteeni"])},
    "Your_email_address_has_been_verified_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköpostisi on vahvistettu."])},
    "Sign_in_to_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu sisään jatkaaksesi."])},
    "Click_here_to_resend_verification_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä vahvistusviesti uudestaan"])},
    "Verification_email_has_been_resent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahvistusviesti on lähetetty uudestaan"])}
  },
  "user_profile": {
    "Reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo uusi salasana"])},
    "Edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa profiilia"])},
    "Delete_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista käyttäjätunnus"])},
    "Are_you_sure_you_want_to_delete_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oletko varma että haluat poistaa käyttäjätunnuksen?"])},
    "This_action_cannot_be_undone_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätä toimintoa ei voi peruuttaa."])},
    "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallenna"])},
    "User_Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjäprofiili"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista"])},
    "Reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nollaa"])}
  },
  "common5": {
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peru"])},
    "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä"])}
  },
  "get_started": {
    "Your_knitting_design_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtuaalinen neuleassarisi"])},
    "Unleash_your_creativity_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päästä luovuutesi valloilleen ja "])},
    "Unleash_your_creativity_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neulo "])},
    "Unleash_your_creativity_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ideasi toteen"])},
    "Knittable_is_your_interactive_tool_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knittablen interaktiiviset neuleohjeet auttavat sinua luomaan täydellisen neuleen langalla kuin langalla. Tarvitset vain neuletiheyden ja koon, Knittable laskee ohjeen valmiiksi puolestasi."])},
    "Try_it_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokeile nyt ilmaiseksi"])}
  },
  "how_does_it_work": {
    "How_does_it_work_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miten se toimii?"])},
    "1_step_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse lanka"])},
    "2_step_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse koko"])},
    "3_step_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ala neulomaan"])},
    "1_step_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knittable toimii millä tahansa lankatyypillä. Kerro Knittablelle valitsemasi langan neuletiheys, ja neuleohje laskelmoidaan puolestasi."])},
    "2_step_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse kokovaihtoehdoista tai kustomoi omien mittojesi mukaan."])},
    "3_step_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nappaa puikot käteen ja ala neulomaan!"])},
    "1_step_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oikean neuletiheyden valitseminen on tärkeää halutun istuvuuden saavuttamiseksi."])},
    "2_step_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittoja käytetään laskelmoimaan valmiin neuleen mittasuhteet, ottaen huomioon väljyysvara ja neuleen mahdollinen huovuttaminen."])},
    "3_step_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna neulepuikkojen rytmin viedä mukanaan ja herätä villeimmät neuleideasi henkiin Knittablen avulla – vain mielikuvitus on rajana!"])}
  },
  "pricing": {
    "Free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilmainen"])},
    "Monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuukausi"])},
    "Yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuosi"])},
    "yearly_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17% alennus"])},
    "paid_yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per kuukausi - $55 vuosittain"])},
    "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per kuukausi"])},
    "Choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaihda tähän tilaustyyppiin"])},
    "Chosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiivinen"])},
    "Next_payment_due_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Seuraava lasku ", _interpolate(_named("nextBillDate"))])},
    "Cancellation_effective_from": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jäsenyyden peruminen astuu voimaan ", _interpolate(_named("cancellationEffectiveDate")), " jälkeen."])},
    "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peruuta"])},
    "Payment_is_past_due_next_retry_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maksu on erääntynyt, seuraava perintäyritys ", _interpolate(_named("nextRetryDate"))])},
    "Subscription_expired_since": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tilaus on vanhentunut ", _interpolate(_named("nextBillDate"))])},
    "Subscription_paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilauksesi on keskeytetty epäonnistuneen maksun vuoksi. Päivitä maksutietosi jatkaaksesi palvelun käyttöä."])},
    "Choose_what_fits_you_best": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse itsellesi sopivin vaihtoehto"])},
    "Knitting_tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuletyökalut"])},
    "Pattern_add_on_for_tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuvio-lisäosa työkaluihin"])},
    "Interactive_knitting_patterns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interaktiiviset neuleohjeet"])}
  },
  "our_patterns": {
    "Our_Patterns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuleohjeemme"])},
    "Our_patterns_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knittable tarjoaa interaktiivisia neuleohjeita, joita voit muokata oman suunnitelmasi mukaisiksi."])},
    "Our_patterns_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuleohjeet sopivat kaikentyyppisille langoille ja kokotyypeille, mukaanlukien sekä lasten että aikuisten vaatekoot. Ohjeet ovat ihanteellisia niin aloittelijoille, jotka etsivät hyviä perusohjeita, että kokeneille neulojille jotka haluavat kustomoida ohjeita omannäköisikseen."])}
  },
  "subscription": {
    "Change_or_cancel_at_any_time_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voit muuttaa tai perua tilauksen milloin vain profiilisi asetuksissa."])},
    "Go_back_to_pattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takaisin"])}
  },
  "gauge_from_swatch": {
    "tool_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä työkalu määrittää neuletiheytesi mallitilkun koon ja silmukkamäärän mukaan."])},
    "Swatch_dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mallitilkun mitat"])},
    "Height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pituus"])},
    "Width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveys"])},
    "Height_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mallitilkun pituus mitattuna (cm)"])},
    "Width_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mallitilkun leveys mitattuna (cm)"])},
    "Number_of_stitches_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silmukoiden määrä mitatulla leveydellä"])},
    "Number_of_rows_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kerrosten määrä mitatulla pituudella"])},
    "Height_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita pituus"])},
    "Width_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita leveys"])},
    "Swatch_dimensions_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittaa mallitilkkusi leveys/pituus ja laske sitten tilkun silmukat/kerrokset."])},
    "Swatch_dimensions_information_bubble_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mallitilkun ei tarvitse olla 10 x 10 cm, mutta suurempi tilkku takaa tarkemman neuletiheyden."])},
    "Your_knitting_gauge_is_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neuletiheytesi on ", _interpolate(_named("stitchGauge")), " silmukkaa x ", _interpolate(_named("rowGauge")), " kerrosta = 10 x 10 cm."])}
  },
  "common6": {
    "Calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laske"])}
  },
  "welcome": {
    "Welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tervetuloa Knittableen"])},
    "You_have_x_left_of_your_free_trial": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sinulla on ", _interpolate(_named("remainingDays")), " päivää jäljellä ilmaisesta kokeilujaksosta"])},
    "View_subscription_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katso tilausvaihtoehdot"])},
    "You_have_access_via_partnership_program_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sinulla on täysi pääsyoikeus Knittableen yhteistyökumppani-ohjelman kautta ", _interpolate(_named("expiresAt")), " asti."])},
    "You_have_access_via_partnership_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinulla on täysi pääsyoikeus Knittableen yhteistyökumppani-ohjelman kautta."])},
    "Your_customers_can_use_the_code": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Asiakkaasi voivat käyttää koodia ", _interpolate(_named("code")), " saadakseen 15% alennuksen vuositilauksen ensimmäisestä vuodesta."])}
  },
  "tracking_behavior": {
    "We_would_like_to_track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käytämme evästeitä ja vastaavia teknologioita kerätäksemme anonyymejä käyttäjätietoja, analytiikkatarkoituksiin ja parantaaksemme käyttäjätyytyväisyyttä. Onko tämä ok?"])},
    "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyllä"])},
    "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei"])}
  },
  "pattern_form": {
    "A_new_version_is_available_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uusi versio ohjeesta on saatavilla. Viimeistele tämänhetkinen projektisi ja päivitä ohje uuteen versioon seuraavaa neuletyötäsi varten."])},
    "Upgrade_and_calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä ja laske ohje uudestaan"])}
  },
  "common7": {
    "Knit_calculator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulelaskuri"])},
    "Subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilaa"])},
    "Subscribe_to_enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilaa ottaaksesi käyttöön"])},
    "Change_gauge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuletiheyden muunnin"])}
  },
  "knit_calculator": {
    "tool_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä työkalu käyttää neuletiheyttäsi laskeakseen, kuinka monta silmukkaa tai kerrosta tarvitset neuloaksesi kappaleen haluttuun leveyteen tai pituuteen."])},
    "stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["silmukkaa"])},
    "rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kerrosta"])},
    "Stitch_gauge_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silmukoiden määrä per 10 cm"])},
    "Row_gauge_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kerrosten määrä per 10 cm"])},
    "Width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveys"])},
    "Width_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita haluttu leveys"])},
    "Width_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuleen haluttu leveys (cm)"])},
    "Length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pituus"])},
    "Length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita haluttu pituus"])},
    "Length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuleen haluttu pituus (cm)"])},
    "Project_dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinun projektisi"])},
    "Project_dimensions_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuletiheys muodostuu silmukkatiheydestä ja kerrostiheydestä. Silmukkatiheys määrittää silmukoiden määrän 10 cm leveydellä, kerrostiheys määrittää kerrosten määrän 10 cm pituudella."])},
    "Project_dimensions_information_bubble_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silmukoiden tai kerrosten määrä lasketaan neuletiheytesi perusteella, yhdistettynä pituuteen tai leveyteen jonka haluat neuloa."])},
    "Pattern_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuvio"])},
    "Pattern_repetition_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käytä tätä osiota, jos haluat lisätä kuviokerran neuleesesi."])},
    "Pattern_repetition_information_bubble_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ylimääräiset silmukat sisältyvät laskelmointiin mutta niitä ei toisteta, esim. silmukat joita tarvitaan kuviokerran viimeistelyyn tai reunasilmukat. Esimerkki: jos neuleen molemmat reunat tarvitsevat 5 reunasilmukkaa, ja 1 silmukka vaaditaan kuviokerran viimeistelyyn, silloin ylimääräiset silmukat ovat 5 + 5 + 1 = 11."])},
    "Pattern_repetition_information_bubble_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ylimääräisiä silmukoita käytetään usein neuloessa tasona, mutta niitä ei yleensä tarvita suljetussa neuleessa ollenkaan."])},
    "Pattern_stitch_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silmukkamäärä"])},
    "Pattern_stitch_count_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita kuvion silmukkamäärä"])},
    "Pattern_stitch_count_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarvittava silmukkamäärä jokaiseen kuviokertaan."])},
    "Additional_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ylimääräiset silmukat"])},
    "Additional_stitches_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita ylimääräisten silmukoiden määrä"])},
    "Additional_stitches_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silmukkamäärä joka vaaditaan kuviokerran viimeistelyyn"])},
    "Pattern_row_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kerrosmäärä"])},
    "Pattern_row_count_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita kuvion kerrosten määrä"])},
    "Pattern_row_count_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarvittavien kerrosten määrä jokaiseen kuviokertaan"])},
    "Additional_rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ylimääräiset kerrokset"])},
    "Additional_rows_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita ylimääräisten kerrosten määrä"])},
    "Additional_rows_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ylimääräiset kerrokset, jotka tarvitaan kuviokerran viimeistelyyn"])},
    "Stitches_equals": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("stitchCount")), " silmukkaa on ", _interpolate(_named("width")), " cm."])},
    "Rows_equal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("rowCount")), " kerrosta on ", _interpolate(_named("length")), " cm."])},
    "Stitches_equals_with_pattern": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("stitchCount")), " silmukkaa on ", _interpolate(_named("width")), " cm (", _interpolate(_named("widthDifference")), " cm). Tämä sisältää ", _interpolate(_named("patternRepetition")), " kuviokertaa ja ", _interpolate(_named("patternAdditionalCount")), " ylimääräistä silmukkaa."])},
    "Rows_equals_with_pattern": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("rowCount")), " kerrosta vastaa ", _interpolate(_named("length")), " cm (", _interpolate(_named("lengthDifference")), " cm). Tämä sisältää ", _interpolate(_named("patternRepetition")), " kuvion toistot ja ", _interpolate(_named("patternAdditionalCount")), " ylimääräiset kerrokset."])}
  },
  "gauge_converter": {
    "tool_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä työkalu muuntaa silmukkamäärän tai kerrosmäärän saavuttaakseen vastaavan leveyden tai pituuden uudella neuletiheydellä. Työkalu on hyödyllinen, kun haluat esimerkiksi vaihtaa langan toisenlaiseen ohjeessa."])},
    "Current_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä osio koskee uutta neuletiheyttäsi, eli sitä johon haluat vaihtaa projektisi vanhan neuletiheyden."])},
    "Original_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä osio koskee alkuperäistä neuletiheyttäsi, eli sitä jonka haluat vaihtaa toiseen. Tämä neuletiheys voi olla peräisin esim. Neuleohjeesta, ja sisältää arvioidun silmukka- ja kerrosmäärän."])},
    "Current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinun projektisi"])},
    "Original": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkuperäinen"])},
    "Number_of_stitches_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silmukkamäärä jonka haluat muuntaa"])},
    "Number_of_rows_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kerrosmäärä jonka haluat muuntaa"])},
    "Stitch_gauge_current_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämänhetkinen silmukoiden määrä per 10 cm"])},
    "Row_gauge_current_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämänhetkinen kerrosten määrä per 10 cm"])},
    "Stitch_gauge_original_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkuperäinen silmukoiden määrä per 10 cm"])},
    "Row_gauge_original_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkuperäinen kerrosten määrä per 10 cm"])},
    "Original_stitch_count_equals_cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Haluttu leveys on ", _interpolate(_named("width")), " cm."])},
    "Original_row_count_equals_cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Haluttu pituus on ", _interpolate(_named("length")), " cm."])},
    "Stitches_equals": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tämä vastaa ", _interpolate(_named("stitchCount")), " silmukkaa tämänhetkisellä silmukkatiheydelläsi."])},
    "Rows_equal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tämä vastaa ", _interpolate(_named("rowCount")), " kerrosta tämänhetkisellä kerrostiheydelläsi."])}
  },
  "common_tools": {
    "Calculate_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laske"])},
    "Stitch_gauge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silmukkatiheys"])},
    "Stitch_gauge_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita silmukoiden määrä per 10 cm"])},
    "Row_gauge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kerrostiheys"])},
    "Row_gauge_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita kerrosten määrä per 10 cm"])},
    "Number_of_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silmukkamäärä"])},
    "Number_of_rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kerrosmäärä"])},
    "Number_of_stitches_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita silmukkamäärä"])},
    "Number_of_rows_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita kerrosten määrä"])}
  },
  "forgot_password": {
    "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä"])},
    "New_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uusi salasana"])},
    "A_new_password_has_successfully_been_requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmista! Klikkaa sähköpostiisi saapunutta linkkiä vaihtaaksesi salasanasi uuteen."])},
    "Password_successfully_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salasanan vaihtaminen onnistui."])}
  },
  "sweater": {
    "chest_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rinnanympärys."])},
    "Fit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istuvuus"])},
    "Shape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muoto"])},
    "Fitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istuva"])},
    "Tailored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Räätälöity"])},
    "Relaxed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rento"])},
    "Loose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löysä"])},
    "Oversized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ylisuuri"])},
    "Choose_fit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse istuvuus"])},
    "Chest_circumf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinnanympärys."])},
    "Chest_circumf_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kropan ympärys (ei neuleen) rinnan yli sen leveimmästä kohdasta (cm)"])},
    "Type_in_chest_circumf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita rinnanympärys"])},
    "Body_ease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väljyys"])},
    "Body_ease_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinnanympärykseen lisätty väljyys, jotta saadaan lopullinen vaatteen rinnanympärys (cm)"])},
    "Type_in_body_ease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita väljyys"])},
    "Finished_chest_circumf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmis rinnanympärys"])},
    "Finished_chest_circumf_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuleen ympärysmitta (ei kropan) rinnan yli sen leveimmästä kohdasta (cm)"])},
    "Type_in_finished_chest_circumf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita valmis rinnanympärys"])},
    "Sweater_length_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulepaidan pituus"])},
    "Sweater_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulepaidan pituus mitattuna takaosasta niskasta helmaan (cm)"])},
    "Type_in_sweater_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita neulepaidan pituus"])},
    "Hem_length_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helman pituus"])},
    "Hem_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joustinneuleen pituus neulepaidan alaosassa (cm)"])},
    "Type_in_hem_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita helman pituus"])},
    "Cuff_circumf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rannekkeen ympärysmitta"])},
    "Cuff_circumf_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hihojen rannekkeiden ympärysmitta (cm)"])},
    "Type_in_cuff_circumf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita rannekkeen ympärysmitta"])},
    "Wrist_circumf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranteen ympärysmitta"])},
    "Wrist_circumf_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranteen ympärysmitta (cm)"])},
    "Type_in_wrist_circumf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita ranteen ympärysmitta"])},
    "Cuff_length_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rannekkeen pituus"])},
    "Cuff_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hihojen rannekkeiden pituus (cm)"])},
    "Type_in_cuff_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita rannekkeen pituus"])},
    "Neckband_circumf_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pääntien ympärysmitta."])},
    "Neckband_circumf_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pääntien ympärysmitta (cm)"])},
    "Type_in_neckband_circumf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita pääntien ympärysmitta"])},
    "Neckband_length_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pääntien pituus"])},
    "Neckband_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pääntien pituus (cm)"])},
    "Type_in_neckband_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita pääntien pituus"])},
    "Arm_circumf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käsivarren ympärysmitta."])},
    "Arm_circumf_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käsivarren ympärysmitta leveimmästä kohdasta mitattuna (cm)"])},
    "Type_in_arm_circumf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita käsivarren ympärysmitta"])},
    "Sleeve_ease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hihan väljyys"])},
    "Sleeve_ease_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väljyysvara joka lisätään käsivarren ympärysmittaan lopullisen hihan ympärysmitan (cm) saavuttamiseksi"])},
    "Type_in_sleeve_ease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita hihan väljyys"])},
    "Sleeve_circumf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hihan ympärysmitta"])},
    "Sleeve_circumf_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ympärysmitta hihan yläosassa (cm)"])},
    "Type_in_sleeve_circumf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita hihan ympärysmitta"])},
    "Arm_length_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käsivarren pituus"])},
    "Arm_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käsivarren pituus (ei hihan) kainalosta ranteeseen kun käsivarsi roikkuu rentona vartalon vieressä (cm)"])},
    "Type_in_arm_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita käsivarren pituus"])},
    "Raglan_stitch_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raglan silmukoiden määrä"])},
    "Raglan_stitch_count_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silmukoiden määrä jokaisessa raglanissa"])},
    "Type_in_raglan_stitch_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita silmukoiden määrä"])},
    "Body_pattern_repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vartalon kuviokerta"])},
    "Body_pattern_repeat_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silmukoiden määrä yksittäisessä kuviokerrassa neulepaidan vartalossa"])},
    "Type_in_body_pattern_repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita silmukoiden määrä"])},
    "Neckband": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaulus"])},
    "Neckband_cast_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Luo ", _interpolate(_named("neckbandStitchCount")), " silmukkaa pyöröpuikoille, jotka ovat yhden koon pienemmät kuin puikkokoko jota käytit neuletiheyden saavuttamiseksi."])},
    "Neckband_knit_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aseta silmukkamerkki (sm) merkitsemään kerroksen alkukohtaa, liitä suljetuksi neuleeksi ja neulo kaulus joustinneuleella (n", _interpolate(_named("cuffPatternRepCountHalf")), "o, n", _interpolate(_named("cuffPatternRepCountHalf")), "n) kunnes työ on ", _interpolate(_named("neckbandKnittedLength")), " cm pitkä."])},
    "Neckband_fold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taita kaulus kaksinkerroin ja kiinnitä taitettu kaulus seuraavanlaisesti:"])},
    "Neckband_fold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nosta 1s aloitusreunasta ja aseta se vasemmalle puikolle ensimmäisen silmukan eteen, neulo nämä kaksi silmukkaa oikein yhteen. *Nosta seuraava silmukka aloitusreunasta, aseta se vasemmalle puikolle ja neulo 2o yhteen.** Toista *-** koko kerroksen ympäri"])},
    "Yoke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaarroke"])},
    "Yoke_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaarroke neulotaan suljettuna neuleena ja siinä on neljä raglansilmukkaa, joissa lisäykset tehdään molemmilla puolilla joka toisella kierroksella. Raglansilmukat voi neuloa sileällä neuleella tai jatkaen joustinneuletta. Kierroksen alku on sijoitettu selän keskelle."])},
    "Yoke_first_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaihda puikot vastaamaan neuletiheydessä käytettyä puikkokokoa ja neulo 1 kierros samalla asettaen 8 raglan-silmukkamerkkiä (sm) seuraavanlaisesti:"])},
    "Yoke_first_round_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo ", _interpolate(_named("raglanFirstSectionFirstHalf")), " silmukka(a), aseta 1. sm, neulo ", _interpolate(_named("raglanStitchCount")), " raglan silmukka(a), aseta 2. sm, neulo ", _interpolate(_named("raglanSecondSection")), " silmukka(a), aseta 3. sm, neulo ", _interpolate(_named("raglanStitchCount")), " raglan silmukka(a), aseta 4. sm, neulo ", _interpolate(_named("raglanThirdSection")), " silmukka(a), aseta 5. sm, neulo ", _interpolate(_named("raglanStitchCount")), " raglan silmukka(a), aseta 6. sm, neulo ", _interpolate(_named("raglanFourthSection")), " silmukka(a), aseta 7. sm, neulo ", _interpolate(_named("raglanStitchCount")), " raglan silmukka(a), aseta 8. sm ja neulo ", _interpolate(_named("raglanFirstSectionSecondHalf")), " kierroksen lopuksi."])},
    "Work_raglan_increases_as_follows_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jatka nyt neuloen raglanlisäykset:"])},
    "Raglan_increase_round_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["*Neulo sm asti, luo 1 oikein (L1o), neulo ", _interpolate(_named("raglanStitchCount")), " raglan silmukka(a), sm, luo 1 nurin (L1n).** Toista *-** vielä kolme kertaa. Neulo loput kierroksesta."])},
    "Repeat_the_last_two_rounds_x_times_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Toista 1. ja 2. Kierros ", _interpolate(_named("raglanIncreasesMinusOne")), " kertaa (", _interpolate(_named("raglanIncreases")), " yhteensä), kunnes sinulla on ", _interpolate(_named("maxYokeStitchCount")), " silmukkaa puikoilla."])},
    "Body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vartalo"])},
    "Divide_the_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaa silmukat hihoiksi ja vartaloksi samalla luoden uudet silmukat kainaloiden kohdalle seuraavasti:"])},
    "Divide_the_stitches_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo ", _interpolate(_named("rightBackStitchCount")), " silmukkaa, siirrä ", _interpolate(_named("sleeveStitchCountMinusUnderarm")), " silmukkaa odottamaan (esimerkiksi langanpätkälle tai kaapelille) ja luo ", _interpolate(_named("underarmStitchCount")), " silmukkaa kainaloa varten. Neulo ", _interpolate(_named("frontStitchCount")), ", siirrä ", _interpolate(_named("sleeveStitchCountMinusUnderarm")), " silmukkaa odottamaan, luo ", _interpolate(_named("underarmStitchCount")), " silmukkaa kainaloa varten ja neulo ", _interpolate(_named("leftBackStitchCount")), " kierroksen lopuksi."])},
    "Knit_additional_x_to_new_beginning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo ylimääräiset ", _interpolate(_named("stitchCountToNewBeginning")), " silmukkaa ja aseta sm merkitsemään kierroksen aloituskohtaa."])},
    "There_should_now_be_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sinulla pitäisi nyt ", _interpolate(_named("bodyStitchCount")), " silmukkaa puikoilla."])},
    "Pattern_test_knit_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tätä ohjetta parhaillaan testineulotaan, joten se ei välttämättä ole vielä täysin hiottu. Käytäthän ohjetta varauksella, ja ota yhteyttä sähköpostitse osoitteeseen ", _interpolate(_named("email")), ", jos sinulla on kysyttävää."])},
    "Cropped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lyhyt"])},
    "Regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normaali"])},
    "Long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitkä"])},
    "Length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pituus"])},
    "Choose_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse pituus"])},
    "Knit_in_the_round_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo suljettuna neuleena kunnes vartalon pituus on ", _interpolate(_named("sweaterLengthMinusHem")), " cm keskiselästä mitattuna, kaulus mukaanlukien."])},
    "Change_needle_and_knit_the_hem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vaihda yhtä kokoa pienempiin puikkoihin ja neulo ", _interpolate(_named("hemLength")), " cm joustinneuletta (n ", _interpolate(_named("cuffPatternRepCountHalf")), "o, n", _interpolate(_named("cuffPatternRepCountHalf")), "n)."])},
    "Finished_measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmiin neuleen mitat"])},
    "Measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitat"])},
    "Requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toivottu"])},
    "Result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmis"])},
    "Difference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erotus"])},
    "Size_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vartalon ympärysmitta (ei paidan) rinnan yli mitattuna"])},
    "Bind_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päättele silmukat haluamallasi tekniikalla, esim. tavallinen päättely tai italialainen päättely."])},
    "Sleeves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hihat"])},
    "The_sleeves_are_worked_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hihat neulotaan suljettuna neuleena sukkapuikoilla, tai pyöröpuikoilla “magic loop”-tekniikkaa hyödyntäen."])},
    "Transfer_stitches_to_needles": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Siirrä ", _interpolate(_named("sleeveStitchCountMinusUnderarm")), " odottamassa ollutta hihan silmukkaa takaisin puikoille."])},
    "Sleeves_first_round_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Poimi ja neulo ", _interpolate(_named("underarmCeilHalfStitchCount")), " silmukkaa, aloittaen kainalosilmukoiden keskeltä. Neulo ", _interpolate(_named("sleeveStitchCountMinusUnderarm")), " silmukkaa, poimi ja neulo loput ", _interpolate(_named("underarmFloorHalfStitchCount")), " kainalosilmukkaa. Puikoilla on nyt ", _interpolate(_named("sleeveStitchCount")), " silmukkaa."])},
    "Sleeves_continue_knitting_in_the_round": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aseta sm merkitsemään kierroksen aloituskohtaa, liitä suljetuksi neuleeksi ja neulo kunnes hiha on j", _interpolate(_named("sleeveAboveDecreasesLength")), " cm pitkä kainalosta mitattuna."])},
    "Work_the_decreases_as_follows_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aloita nyt kavennukset seuraavanlaisesti:"])},
    "Knit_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulo 1."])},
    "Sleeve_decrease_round_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulo 2o yhteen, neulo kunnes puikoilla on jäljellä 2 silmukkaa. Nosta 1o neulomatta, neulo 1o, vedä nostettu silmukka neulotun yli."])},
    "There_are_now_x_stitches_on_your_needles_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Puikoilla on nyt ", _interpolate(_named("stitchCount")), " silmukkaa."])},
    "Continue_working_the_sleeve_performing_decreases_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jatka hihan neulomista ja neulo kavennuskierros joka ", _interpolate(_named("betweenDecreasesRoundCountPlusOne")), ":s kierros, ", _interpolate(_named("decreaseRoundCountMinusOne")), " kertaa (", _interpolate(_named("decreaseRoundCount")), " yhteensä)."])},
    "Knit_in_the_round_until_the_sleeve": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo suljettua neuletta kunnes hiha on ", _interpolate(_named("sleeveLengthMinusCuffLength")), " cm pitkä kainalosta mitattuna."])},
    "Knit_the_last_two_stitches_togeter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo kierroksen 2 viimeistä silmukkaa oikein yhteen saavuttaaksesi silmukkamäärän ", _interpolate(_named("cuffStitchCount")), "."])},
    "Knit_the_two_stitches_togeter_x_times": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vähennä silmukkamäärää ", _interpolate(_named("cuffStitchCount")), " neulomalla 2o yhteen ", _interpolate(_named("additionalDecreaseCount")), " kertaa tasaisesti viimeisen kierroksen aikana."])},
    "Change_to_a_needle_size_smaller_to_knit_the_cuff": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vaihda yhtä kokoa pienempään puikkokokoon ja neulo joustinneuletta (n", _interpolate(_named("cuffPatternRepCountHalf")), "o, n", _interpolate(_named("cuffPatternRepCountHalf")), "n) kunnes ranneke on ", _interpolate(_named("cufflength")), " cm pitkä."])},
    "Finishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viimeistely"])},
    "Weave_in_and_block_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päättele kaikki langanpätkät, kastele neulepaita ja asettele se mittoihinsa kuivumaan tasaiselle alustalle."])},
    "Shape_neck_with_short_rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muotoile niska lyhennetyillä kierroksilla"])},
    "Single_ribbing_raglan_stitch_count_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raglansilmukoiden määrä, joka sopii parhaiten yksinkertaiseen joustinneuleeseen, on pariton luku, kuten 1, 3, 5, 7 jne"])},
    "Double_ribbing_raglan_stitch_count_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raglansilmukoiden määrä, joka sopii parhaiten kaksinkertaiseen joustinneuleeseen, on parillinen kertoluku parittomasta luvusta, kuten 2, 6, 10, 14 jne"])},
    "Body_not_wide_enough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmiin vartalon ympärysmitta ei ole tarpeeksi suuri verrattuna hihan ympärysmittaan. Kokeile suurempaa vartalon väljyyttä tai pienempää hihan väljyyttä."])},
    "Sleeve_not_wide_enough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hihan ympärysmitta ei ole tarpeeksi suuri verrattuna vartalon ympärysmittaan. Kokeile suurempaa hihan väljyyttä tai pienempää vartalon väljyyttä."])},
    "Raglan_too_wide": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Raglanit ovat liian leveät. Kokeile pienempää raglan-silmukkamäärää tai suurempaa vartalon väljyyttä. Suurin sallittu raglan-silmukkamäärä valituille mitoille on ", _interpolate(_named("maxRaglanStitchCount")), "."])},
    "Now_the_neck_is_shaped_using_short_rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyt kaula-aukko muotoillaan lyhennetyillä kerroksilla samalla, kun aloitetaan raglanlisäykset. Suosittelemme “German short row”-tekniikkaa, mutta myös muita lyhennetyn kierroksen menetelmiä voi käyttää."])},
    "First_work_short_rows_on_back_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensin neulo lyhennettyjä kierroksia neulepaidan selkäpuolelle seuraavanlaisesti:"])},
    "Continue_working_raglan_increases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jatka suljettuna neuleena neulomista, neuloen samalla raglanlisäyksiä seuraavanlaisesti:"])},
    "First_neck_shaping_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo ", _interpolate(_named("neckShapingStepStitchCount")), " silmukkaa, käännä työ."])},
    "Knit_neck_shaping_row_back": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo kierroksen alkuun, neulo ", _interpolate(_named("neckShapingStepStitchCount")), " silmukkaa, käännä työ."])},
    "Purl_neck_shaping_row_back": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo nurjaa kierroksen alkuun, neulo nurin ", _interpolate(_named("neckShapingStepStitchCount")), " silmukkaa, käännä työ."])},
    "Work_the_last_two_rows_back": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo nämä kaksi kierrosta vielä ", _interpolate(_named("neckShapingRightTurnsOnBackMinusTwo")), " kertaa (kunnes olet tehnyt ", _interpolate(_named("neckShapingRightTurnsOnBack")), " käännöstä kokonaisuudessaan oikealla puolella ja ", _interpolate(_named("neckShapingRightTurnsOnBack")), " nurjalla puolella), aina neuloen  ", _interpolate(_named("neckShapingStepStitchCount")), " silmukkaa pidemmälle jokaisella kierroksella."])},
    "Now_work_short_rows_on_front_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neulo nyt lyhennettyjä kierroksia, jotka yltävät neulepaidan etuosaan seuraavanlaisesti:"])},
    "Knit_neck_shaping_row_front": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo kierroksen alkuun. *Neulo seuraavalle sm, luo 1 oikein (L1o), neulo ", _interpolate(_named("raglanStitchCount")), " raglan silmukka(a), sm, luo 1 nurin (L1n)** Toista *-** kerran neuloaksesi lisäykset myös seuraavaan raglaniin. Neulo ", _interpolate(_named("stitchCountFromRaglanToTurn")), " silmukka(a), käännä työ."])},
    "Purl_neck_shaping_row_front": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo nurjaa kierroksen alkuun, *neulo nurjaa seuraavalle sm, L1n, neulo nurjana ", _interpolate(_named("raglanStitchCount")), " raglan silmukka(a), sm, L1o.** Toista *-** kerran neuloaksesi lisäykset myös seuraavaan raglaniin. Neulo nurjaa ", _interpolate(_named("stitchCountFromRaglanToTurn")), " silmukka(a), käännä työ."])},
    "Work_the_last_two_rows_front": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo nämä kaksi kierrosta vielä ", _interpolate(_named("neckShapingRightTurnsOnFrontMinusOne")), " kertaa (kunnes olet tehnyt yhteensä ", _interpolate(_named("neckShapingRightTurns")), " käännöstä oikealla puolella ja ", _interpolate(_named("neckShapingRightTurns")), " nurjalla puolella), aina neuloen ", _interpolate(_named("neckShapingStepStitchCount")), " silmukkaa pidemmälle joka kierroksella."])},
    "Knit_to_the_beginning_of_the_round": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neulo kierroksen alkuun. Nyt puikoilla pitäisi olla ", _interpolate(_named("stitchCountAfterNeckShaping")), " silmukkaa."])},
    "Neck_shaping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaula-aukon muotoilu"])},
    "Neckband_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaula-aukon tyyppi"])},
    "Plain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tavallinen"])},
    "Folded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taitettu"])},
    "Sleeve_decreases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hihan kavennukset"])},
    "Raglan_increases_only_on_the_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raglanlisäykset vain vartalossa"])},
    "Raglan_increases_only_on_the_sleeves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raglanlisäykset vain hihoissa"])},
    "Now_work_raglan_increases_on_the_body_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hihojen silmukkamäärä on nyt saavutettu. Jatka nyt raglanlisäyksiä vain vartalolle seuraavasti:"])},
    "Now_work_raglan_increases_on_the_sleeves_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vartalon silmukkamäärä on nyt saavutettu. Jatka nyt raglanlisäyksiä vain hihoille seuraavasti:"])},
    "Raglan_increases_on_body_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["*Neulo seuraavaan sm asti, L1o, neulo ", _interpolate(_named("raglanStitchCount")), " raglansilmukka(a), sm, neulo seuraavalle sm asti, neulo ", _interpolate(_named("raglanStitchCount")), " raglansilmukka(a), L1n.** Toista *-** vielä 1 kertaa. Neulo loput kierroksesta."])},
    "Raglan_increases_on_sleeves_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["*Neulo seuraavaan sm asti, neulo ", _interpolate(_named("raglanStitchCount")), " raglansilmukka(a), sm, L1n, neulo seuraavaan sm asti, L1o, neulo ", _interpolate(_named("raglanStitchCount")), " raglansilmukka(a).** Toista *-** vielä 1 kertaa. Neulo loput kierroksesta."])},
    "Neck_shaping_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaula-aukon muotoilu epäonnistui. Tämä johtuu todennäköisesti siitä, että raglan-silmukoiden välille jäävä silmukkamäärä paidan edessä ja takana on liian vähäinen. Kokeile vähentää raglan-silmukoiden määrää, lisätä vartalon väljyyttä tai vähentää hihan väljyyttä."])},
    "default_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtui virhe. Yritä uudelleen käyttäen erilaisia asetuksia."])},
    "Pattern_updated_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tätä ohjetta on päivitetty sen jälkeen, kun tallensit sen viimeksi. Tutustu uuteen, parannettuun ohjeeseen ja ota yhteyttä sähköpostitse osoitteeseen ", _interpolate(_named("email")), " jos sinulla on kysyttävää tai palautetta."])},
    "Change_person_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokokategorian vaihtaminen nollaa muut koon ja muodon asetukset."])}
  },
  "news_letter_sign_up": {
    "Join_the_inner_circle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liity sisäpiiriin – tilaa uutiskirjeemme."])},
    "Want_to_be_the_first_to_know": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko kuulla ensimmäisten joukossa uusista ohjeista, tapahtumista ja testineulonnoista? Tilaa nyt ja pääset sisäpiiriimme!"])},
    "Thank_you_for_subscribing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiitos tilauksestasi!"])},
    "This_email_is_already_subscribed_to_the_newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä sähköposti on jo uutiskirjelistallamme."])}
  }
}