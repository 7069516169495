<template>
  <button :class="['main-button', colorClass, { disabled: disabled, compact: compact }]">
    <slot></slot>
  </button>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: "light",
    validator: (value) => ["light", "purple", "yellow", "red"].includes(value),
  },
  compact: {
    type: Boolean,
    default: false,
  },
});

const colorClass = computed(() => {
  return `main-button-${props.color}`;
});
</script>

<style scoped lang="scss">
@import "./../../../scss/styles.scss";

.main-button {
  @include buttonText;

  display: inline-flex;
  padding: 1.125rem 4rem;
  justify-content: center;
  align-items: center;

  border-radius: 3rem;

  &:focus-visible {
    outline:none;
  }
}

.main-button.compact {
  padding: 0.5rem 1.5rem;
}

.main-button-light {
  color: var(--knittable-lav-darker);
  background-color: var(--knittable-isa);
  border: 2px solid var(--knittable-lav-light);
  &:hover,
  &:focus-visible {
    background-color: var(--knittable-lav-darker);
    color: var(--knittable-isa);
  }

  &.disabled {
    color: var(--knittable-lav-light);
    background-color: var(--knittable-isa);
    cursor: not-allowed;
  }
}

.main-button-purple {
  color: white;
  background-color: var(--knittable-lav);
  &:hover,
  &:focus-visible {
    background-color: var(--knittable-lav-darker);
  }

  &.disabled {
    color: white;
    background-color: var(--knittable-lav-light);
    cursor: not-allowed;
  }
}

.main-button-yellow {
  color: white;
  background-color: var(--knittable-yama);
  &:hover,
  &:focus-visible {
    background-color: var(--knittable-yama-darker);
  }

  &.disabled {
    color: white;
    background-color: var(--knittable-yama-light);
    cursor: not-allowed;
  }
}

.main-button-red {
  color: white;
  background-color: var(--knittable-begonia);
  &:hover,
  &:focus-visible {
    background-color: var(--knittable-ama);
  }

  &.disabled {
    color: white;
    background-color: var(--knittable-begonia-light);
    cursor: not-allowed;
  }
}
</style>