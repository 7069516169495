<template>
  <button class="facebook-login-button" @click="loginWithFacebook">
    <v-icon class="fb-icon">mdi-facebook</v-icon> {{ $t("social_auth.Continue_with_Facebook") }}
  </button>
</template>

<script setup>
import { ref, defineProps } from "vue";

import { useRouter } from "vue-router";
import { useAuthStore } from "./../../stores/auth";
import { composeRoute } from "./../../lib/navigationUtils";
import { useLangStore } from "./../../stores/lang";

const router = useRouter();
const langStore = useLangStore();
const locale = langStore.getLocale();

const loading = ref(false);

const authStore = useAuthStore();

const props = defineProps({
  next: {
    type: String,
    required: true,
  },
});

const handleAuthResponse = (authResponse) => {
  FB.api('/me', { fields: 'id,first_name,last_name,email' }, (userData) => {
    console.log("User Data:", userData);

    authStore
      .facebookAuth(
        userData.first_name,
        userData.last_name,
        userData.email,
        authResponse.signedRequest
      )
      .then(() => {
        router.push(composeRoute(locale.value, props.next));
      });
  });
};

const loginWithFacebook = () => {
  loading.value = true;
  try {
    FB.getLoginStatus((statusResponse) => {
      if (statusResponse.status && statusResponse.status === "connected") {
        console.log("Already connected", statusResponse);
        handleAuthResponse(statusResponse.authResponse);
      } else {
        console.log("Not connected", statusResponse);
        FB.login(handleLoginResponse);
      }
    });
  } catch (error) {
    alert(error);
  } finally {
    loading.value = false;
  }
};

const handleLoginResponse = (response) => {
  console.log("handleLoginResponse", response);
  if (!response.status || response.status !== "connected") {
    alert("User cancelled login or did not fully authorize.");
    return;
  }

  handleAuthResponse(response.authResponse);
};
</script>

<style scoped lang="scss">
@import "./../../../scss/styles.scss";

.facebook-login-button {
  @include paragraphSmallBold;
  background-color: var(--knittable-facebook-blue);
  color: white;
  height: 38px;
  border-radius: 40px;
  padding: 10px;
  min-width: 219px;
  white-space: nowrap;
}

.fb-icon {
  margin-right: 3px;
}
</style>
